<template>
    <v-container class="home-container" fluid>
        <v-row class="home-btn-row mt-2 mb-1" no-gutters>
            <v-col cols="12" class="d-flex justify-center mb-10">
                <div v-if="isTimerDisplayed"
                     class="banner-timer-container">
                    <img class="home-banner-img golden pa-1 rounded-lg elevation-6"
                         src="/img/home/banner-s3-before.webp" alt="Wizarre banner"/>
                    <timer :count-upwards="false" :deadline-timestamp="timestamps.countdown.end"
                           class="banner-timer bold"/>
                </div>
                <div v-else>
                    <img class="home-banner-img golden pa-1 rounded-lg elevation-6"
                         src="/img/home/banner-s3-active.webp" alt="Wizarre banner"/>
                </div>
<!--                <div>-->
<!--                    <img class="home-banner-img golden pa-1 rounded-lg elevation-6"-->
<!--                         src="/img/home/banner-s1-active.webp" alt="Wizarre banner"/>-->
<!--                </div>-->
            </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="12" md="2" v-for="card in buttons" :key="'c'+card.routeName">
                <navigation-card
                    :key='card.title'
                    :title='card.title'
                    :routeName='card.routeName'
                    :imgUrl='card.imgUrl'
                    :text='card.text'
                    :disabled="card.disabledNoWeb3?!isWalletConnected:false"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PageFiltersSection from "../components/page-filters-section";
import NavigationCard from '../components/navigation-card.vue';
import Timer from "@/components/timer";
import {dateToTimestamp} from "@/helpers/timeHelper";

export default {
    components: {Timer, PageFiltersSection, NavigationCard},
    data: () => {
        return {
            timestamps: {
                countdown: {
                    start: dateToTimestamp(new Date(Date.UTC(2024, 10, 1, 12, 0, 0))),
                    end: dateToTimestamp(new Date(Date.UTC(2024, 10, 15, 12, 0, 0))),
                }
            },
            buttons: [
                {
                    title: 'Challenges',
                    routeName: 'Profile',
                    imgUrl: 'img/home/rev-wizard.png',
                    text: 'Win rewards for playing game',
                    disabledNoWeb3: true,
                },
                {
                    title: 'Marketplace',
                    routeName: 'Marketplace',
                    imgUrl: 'img/home/marketplace.png',
                    text: 'Trade with other players'
                },
                {
                    title: 'Leaderboard',
                    routeName: 'Leaderboard',
                    imgUrl: 'img/home/home-leaderboard.png',
                    text: 'Get rewards for positions in seasoned leaderboard'
                },
                {
                    title: 'Summoning',
                    routeName: 'Summoning',
                    imgUrl: 'img/home/ritual-of-summoning.png',
                    text: 'Summon new Wizards'
                },
                {
                    title: 'Buy SCRL',
                    routeName: 'BuyToken',
                    imgUrl: 'img/home/scrl-two.png',
                    text: 'Buy SCRL token'
                },
            ],
        };
    },
    mounted() {
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
        isTimerDisplayed() {
            const currentTimestamp = Math.round(Date.now() / 1000);
            return currentTimestamp >= this.timestamps.countdown.start && currentTimestamp <= this.timestamps.countdown.end
        },
    },
}
</script>

<style lang="scss" scoped>
.banner-timer-container {
    position: relative;
}

.banner-timer {
    position: absolute;
    top: 46%;
    left: 38%;
    transform: translate(-50%, -50%);
    font-size: 70px;
    font-family: 'Sansita', sans-serif;
    font-weight: bold;
    //color: var(--v-golden-base);
    color: #f8b100;
}

.home-banner-img {
    width: 600px;
    max-width: 600px;
    height: auto;
    margin: auto;
    aspect-ratio: 600/255;
    display: block;
}

.title-welcome {
    font-size: 96px;
    margin: -10px 24px 0 0;
    text-transform: none;
}

img {
    height: 96px;
}

.home-btn-row {
    margin: 0 0;
}

@media only screen and (min-width: $lg) {
    .home-btn-row {
        margin: 0;
    }
}

@media only screen and (max-width: $md) {
    .title-header {
        .title-welcome {
            font-size: 81px;
        }

        img {
            height: 81px;
        }
    }
}

@media only screen and (max-width: $sm) {
    .home-banner-img {
        width: 100%;
    }
    .title-header {
        .title-welcome {
            font-size: 60px;
        }

        img {
            height: 80px;
        }
    }
    ::v-deep .top-section {
        height: 120px;
    }
}

@media only screen and (max-width: $xs) {
    .title-header {
        .title-welcome {
            font-size: 60px;
            margin-right: 10px;
        }

        img {
            height: 60px;
        }
    }

    .banner-timer {
        font-size: 40px;
    }
}

@media only screen and (max-width: $xxs) {
    .title-header {
        .title-welcome {
            font-size: 40px;
            margin-right: 5px;
        }

        img {
            height: 40px;
        }
    }

    .banner-timer {
        font-size: 20px;
    }
}
</style>
