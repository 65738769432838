var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "home-container", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "home-btn-row mt-2 mb-1", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-center mb-10",
              attrs: { cols: "12" },
            },
            [
              _vm.isTimerDisplayed
                ? _c(
                    "div",
                    { staticClass: "banner-timer-container" },
                    [
                      _c("img", {
                        staticClass:
                          "home-banner-img golden pa-1 rounded-lg elevation-6",
                        attrs: {
                          src: "/img/home/banner-s3-before.webp",
                          alt: "Wizarre banner",
                        },
                      }),
                      _c("timer", {
                        staticClass: "banner-timer bold",
                        attrs: {
                          "count-upwards": false,
                          "deadline-timestamp": _vm.timestamps.countdown.end,
                        },
                      }),
                    ],
                    1
                  )
                : _c("div", [
                    _c("img", {
                      staticClass:
                        "home-banner-img golden pa-1 rounded-lg elevation-6",
                      attrs: {
                        src: "/img/home/banner-s3-active.webp",
                        alt: "Wizarre banner",
                      },
                    }),
                  ]),
            ]
          ),
          _c("v-col", { attrs: { cols: "1" } }),
          _vm._l(_vm.buttons, function (card) {
            return _c(
              "v-col",
              { key: "c" + card.routeName, attrs: { cols: "12", md: "2" } },
              [
                _c("navigation-card", {
                  key: card.title,
                  attrs: {
                    title: card.title,
                    routeName: card.routeName,
                    imgUrl: card.imgUrl,
                    text: card.text,
                    disabled: card.disabledNoWeb3
                      ? !_vm.isWalletConnected
                      : false,
                  },
                }),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }