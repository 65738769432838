<template>
    <v-container class="btn-container pos-relative" :class="{'disabled-card':disabled}">
        <div class="disabled-info-box">
            Connect Web3 Wallet
        </div>
        <v-btn :disabled="disabled" :to="{ name: routeName, params: {}}" block rounded class="nav-button">
            <v-row no-gutters>
                <v-col cols="3" sm="4" md="12" class="img-container d-flex align-center">
                    <img :src="imgUrl" :alt="title"/>
                </v-col>
                <v-col cols="9" sm="8" md="12" class="btn-content">
                    <h2>{{ title }}</h2>
                    <span>{{ text }}</span>
                </v-col>
            </v-row>
        </v-btn>
    </v-container>
</template>

<script>
export default {
    props: {
        title: String,
        routeName: String,
        imgUrl: String,
        text: String,
        disabled: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.btn-container {
    margin: 10px 0 0 0;
    padding: 0 20px;
}

.v-btn.nav-button {
    width: 100%;
    height: 320px;
    vertical-align: top;
    display: block;
    padding: 0;
    margin: 0;
    overflow-wrap: break-word;
    background: linear-gradient(to bottom, var(--v-buttonBg-base) 65%, #38294A 65%, #38294A 100%) !important;
    //background: linear-gradient(to bottom, var(--v-buttonBg-base) 21%,transparent 100%) !important; // gradient
    box-shadow: 0 0 15px #000;

    &:hover {
        box-shadow: 0 0 15px #fff;
    }
}

.disabled-info-box {
    width: 160px;
    margin-left: -80px;
    position: absolute;
    top: -30px;
    left: 50%;
    background: #555;
    border-radius: 10px 10px 0 0;
    padding: 5px;
    display: none;
}

.disabled-card {
    .disabled-info-box {
        display: block;
    }

    .v-btn.nav-button {
        box-shadow: none;
        background: linear-gradient(to bottom, #666 65%, #555 65%, #555 100%) !important;
    }

    img {
        filter: grayscale(1);
    }
}

.img-container {
    text-align: center;
    height: 210px;

    img {
        width: auto;
        max-width: 240px;
        height: auto;
        max-height: 180px;
        margin: auto;
    }
}

.btn-content {
    text-align: center;

    span {
        text-transform: none;
        overflow-wrap: break-word;
        white-space: normal;
    }
}

@media only screen and (max-width: $lg) {
    .btn-container {
        padding: 0 10px;
    }
    .img-container {
        img {
            max-width: 180px;
            max-height: 160px;
        }
    }
}

@media only screen and (max-width: $md) {
    .v-btn.nav-button {
        height: 220px;

        h2 {
            font-size: 20px;
        }

        span {
            font-size: 10px;
        }

        .img-container {
            height: 140px;

            img {
                max-width: 140px;
                max-height: 120px;
            }
        }
    }
}

@media only screen and (max-width: $sm) {
    .v-btn.nav-button {
        height: 120px;
        background: linear-gradient(to right, var(--v-buttonBg-base) 33%, #38294A 33%, #38294A 100%) !important;

        h2 {
            font-size: 40px;
            overflow: hidden;
        }

        span {
            font-size: 16px;
        }

        .img-container {
            height: 120px;

            img {
                max-width: 120px;
                max-height: 110px;
                margin-top: 5px;
            }
        }
    }
}

@media only screen and (max-width: $xs) {
    .v-btn.nav-button {
        height: 100px;
        background: linear-gradient(to right, var(--v-buttonBg-base) 25%, #38294A 25%, #38294A 100%) !important;

        h2 {
            font-size: 30px;
        }

        span {
            font-size: 16px;
        }

        .img-container {
            height: 100px;

            img {
                max-width: 80px;
                max-height: 85px;
                margin: auto;
            }
        }
    }
}

@media only screen and (max-width: $xxs) {
    .v-btn.nav-button {
        h2 {
            font-size: 35px;
        }

        span {
            font-size: 12px;
        }

        .img-container {
            height: auto;

            img {
                max-width: 80px;
                max-height: 100px;
                margin-top: 10px;
            }
        }
    }
}

</style>
