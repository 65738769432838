var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "btn-container pos-relative",
      class: { "disabled-card": _vm.disabled },
    },
    [
      _c("div", { staticClass: "disabled-info-box" }, [
        _vm._v(" Connect Web3 Wallet "),
      ]),
      _c(
        "v-btn",
        {
          staticClass: "nav-button",
          attrs: {
            disabled: _vm.disabled,
            to: { name: _vm.routeName, params: {} },
            block: "",
            rounded: "",
          },
        },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "img-container d-flex align-center",
                  attrs: { cols: "3", sm: "4", md: "12" },
                },
                [_c("img", { attrs: { src: _vm.imgUrl, alt: _vm.title } })]
              ),
              _c(
                "v-col",
                {
                  staticClass: "btn-content",
                  attrs: { cols: "9", sm: "8", md: "12" },
                },
                [
                  _c("h2", [_vm._v(_vm._s(_vm.title))]),
                  _c("span", [_vm._v(_vm._s(_vm.text))]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }